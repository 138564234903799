import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; // For rxjs 6
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class WalletManage { 
  baseUrl = 'https://acepunt.kushubmedia.com/subadmin/';
  headers:string;
  constructor( private http: HttpClient) {}
  
  postData(credentials, type){

    return new Promise((resolve, reject) =>{
      let headers = new HttpHeaders();

      this.http.post(this.baseUrl+type, credentials, {headers: headers}).

      subscribe(res =>{
      
     
        resolve(res);
      }, (err) =>{
       
        reject(err);
      });
    });
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MyEvent } from '../services/myevent.services';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SocketService } from './socket.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WalletManage } from 'src/services/walletmanage.services';
import { Update } from 'src/services/update.services';
// import { KeysPipe } from './keys.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,HttpClientModule,NgxDatatableModule, IonicModule.forRoot(),IonicStorageModule.forRoot(), AppRoutingModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },MyEvent,Update,WalletManage,SocketService],
  bootstrap: [AppComponent],
})
export class AppModule {}

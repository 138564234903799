import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; // For rxjs 6
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

// @Injectable({
//     // providedIn: 'root'
// })
@Injectable()
export class Update {

    private selectedLanguage = new Subject<string>();
    
  baseUrl = 'https://acepunt.kushubmedia.com/subadmin/';
    // baseUrl = 'http://167.99.86.239:6001/';
 
 headers:string;
    constructor( private http: HttpClient) {
  

    }
Post(type,Data){
      return this.http.post(this.baseUrl+type,Data);
    }

  
postData(credentials, type){

    return new Promise((resolve, reject) =>{
      let headers = new HttpHeaders();

      this.http.post(this.baseUrl+type, credentials, {headers: headers}).

      subscribe(res =>{
      
     
        resolve(res);
      }, (err) =>{
       
        reject(err);
      });
    });
  }


  postDataApi(credentials, type){

    return new Promise((resolve, reject) =>{
      let headers = new HttpHeaders();

      this.http.post(this.baseUrl+type, credentials).

      subscribe(res =>{
      
     
        resolve(res);
      }, (err) =>{
       
        reject(err);
      });
    });
  }

  putData(credentials, type){

    return new Promise((resolve, reject) =>{
  let headers = new HttpHeaders();
 
      this.http.put(this.baseUrl+type, credentials,{headers: headers}).

      subscribe(res =>{
      
     
        resolve(res);
      }, (err) =>{
       
        reject(err);
      });
    });
  }

   

   getData(type){

    return new Promise((resolve, reject) =>{
  let headers = new HttpHeaders();
 
      this.http.get(this.baseUrl+type,{headers: headers}).

      subscribe(res =>{
      
     
        resolve(res);
      }, (err) =>{
       
        reject(err);
      });
    });
  }

 


   get(action){
      return this.http.get(this.baseUrl+action)
    }

    

    put(action,type){
      return this.http.put(this.baseUrl+type,action)
    }

     postApi(action,type){
      return this.http.post(this.baseUrl+type,action)
    }



}


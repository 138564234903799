import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; // For rxjs 6
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MyEvent {

  // private selectedLanguage = new Subject<string>();

  // live
  baseUrl = 'http://178.62.77.178:3006/api/';
  Url = 'http://138.68.129.236:3006/api/';
  domain= "https://acepunt.kushubmedia.com/subadmin/";
  // staging
  // baseUrl = 'http://142.93.40.145:3006/api/';

  headers: string;
  constructor(private http: HttpClient) {


  }
  Post(type, Data) {
    return this.http.post(this.baseUrl + type, Data);
  }

  walletpostData(credentials, type) {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http.post(this.domain + type, credentials, { headers: headers }).

        subscribe(res => {


          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }

  postData(credentials, type) {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http.post(this.baseUrl + type, credentials, { headers: headers }).

        subscribe(res => {


          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }

  Postrefresh(credentials, type) {
    return new Promise((resolve, reject) => {
      this.http.post(this.Url + type, credentials).
        subscribe(res => {
          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }


  postDataApi(credentials, type) {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http.post(this.baseUrl + type, credentials).

        subscribe(res => {


          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }

  putData(credentials, type) {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http.put(this.baseUrl + type, credentials, { headers: headers }).

        subscribe(res => {


          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }



  getData(type) {

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();

      this.http.get(this.baseUrl + type, { headers: headers }).

        subscribe(res => {


          resolve(res);
        }, (err) => {

          reject(err);
        });
    });
  }


  get(action) {
    return this.http.get(this.baseUrl + action)
  }



  put(action, type) {
    return this.http.put(this.baseUrl + type, action)
  }

  postApi(action, type) {
    return this.http.post(this.baseUrl + type, action)
  }



}


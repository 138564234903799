import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  // { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  // {
  //   path: 'welcome',
  //   loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  // },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'ledger',
    loadChildren: () => import('./ledger/ledger.module').then( m => m.LedgerPageModule)
  },
  {
    path: 'market',
    loadChildren: () => import('./market/market.module').then( m => m.MarketPageModule)
  },
  {
    path: 'create-user',
    loadChildren: () => import('./create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'wheelspinner',
    loadChildren: () => import('./wheelspinner/wheelspinner.module').then( m => m.WheelspinnerPageModule)
  },
  {
    path: 'profit-loss',
    loadChildren: () => import('./profit-loss/profit-loss.module').then( m => m.ProfitLossPageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then( m => m.SummaryPageModule)
  },
  
  {
    path: 'open-bets',
    loadChildren: () => import('./open-bets/open-bets.module').then( m => m.OpenBetsPageModule)
  },
  {
    path: 'wallet-manage',
    loadChildren: () => import('./wallet-manage/wallet-manage.module').then( m => m.WalletManagePageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'betlistmodal',
    loadChildren: () => import('./betlistmodal/betlistmodal.module').then( m => m.BetlistmodalPageModule)
  },
  {
    path: 'profitlossreport',
    loadChildren: () => import('./profitlossreport/profitlossreport.module').then( m => m.ProfitlossreportPageModule)
  },
  {
    path: 'bet-commision-model',
    loadChildren: () => import('./bet-commision-model/bet-commision-model.module').then( m => m.BetCommisionModelPageModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then( m => m.PartnerPageModule)
  },
  {
    path: 'userexposure',
    loadChildren: () => import('./userexposure/userexposure.module').then( m => m.UserexposurePageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'offers-details',
    loadChildren: () => import('./offers-details/offers-details.module').then( m => m.OffersDetailsPageModule)
  },
  {
    path: 'playerbattle-markets',
    loadChildren: () => import('./playerbattle-markets/playerbattle-markets.module').then( m => m.PlayerbattleMarketsPageModule)
  },
  {
    path: 'playerbattle-singlemarkets',
    loadChildren: () => import('./playerbattle-singlemarkets/playerbattle-singlemarkets.module').then( m => m.PlayerbattleSinglemarketsPageModule)
  },
  {
    path: 'daily-contest',
    loadChildren: () => import('./daily-contest/daily-contest.module').then( m => m.DailyContestPageModule)
  },
  {
    path: 'daily-contest-question',
    loadChildren: () => import('./daily-contest-question/daily-contest-question.module').then( m => m.DailyContestQuestionPageModule)
  },
  {
    path: 'scheme-list',
    loadChildren: () => import('./scheme-list/scheme-list.module').then( m => m.SchemeListPageModule)
  },
  {
    path: 'scheme-summary',
    loadChildren: () => import('./scheme-summary/scheme-summary.module').then( m => m.SchemeSummaryPageModule)
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then( m => m.ReferralPageModule)
  },
  {
    path: 'referral-summary',
    loadChildren: () => import('./referral-summary/referral-summary.module').then( m => m.ReferralSummaryPageModule)
  },
  {
    path: 'refrel-history',
    loadChildren: () => import('./refrel-history/refrel-history.module').then( m => m.RefrelHistoryPageModule)
  },
  {
    path: 'scheme-user',
    loadChildren: () => import('./scheme-user/scheme-user.module').then( m => m.SchemeUserPageModule)
  },
  {
    path: 'stack-points',
    loadChildren: () => import('./stack-points/stack-points.module').then( m => m.StackPointsPageModule)
  },
  {
    path: 'stack-filter',
    loadChildren: () => import('./stack-filter/stack-filter.module').then( m => m.StackFilterPageModule)
  },
  {
    path: 'wallet-user',
    loadChildren: () => import('./wallet-user/wallet-user.module').then( m => m.WalletUserPageModule)
  },
  {
    path: 'creadit-limit-logs',
    loadChildren: () => import('./creadit-limit-logs/creadit-limit-logs.module').then( m => m.CreaditLimitLogsPageModule)
  },
  {
    path: 'affilate',
    loadChildren: () => import('./affilate/affilate.module').then( m => m.AffilatePageModule)
  },
  {
    path: 'affilate-user/:code',
    loadChildren: () => import('./affilate-user/affilate-user.module').then( m => m.AffilateUserPageModule)
  },
  {
    path: 'welcome-bonus',
    loadChildren: () => import('./welcome-bonus/welcome-bonus.module').then( m => m.WelcomeBonusPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
